import React from "react";

const SuccessMsg = ({siteName, handleAddGuest, counter}) => {
    return (
        <div className="success-msg">
            <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="51" cy="51" r="47" fill="#D8FCDC"/>
                <path d="M41.7695 64.0653C43.0209 65.3166 44.8553 65.6261 46.3951 64.9906C46.9243 64.7833 47.4203 64.4658 47.8478 64.0378L68.8716 43.015C70.5317 41.3551 70.5317 38.6641 68.8716 37.0045C67.2114 35.3449 64.522 35.3449 62.8618 37.0045L44.7952 55.0698L38.7646 49.0393C37.1045 47.3797 34.415 47.3797 32.7549 49.0393C31.0947 50.6992 31.0947 53.39 32.7549 55.0499L41.7695 64.0653Z" fill="#0EAD30"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 51C0 79.1665 22.8335 102 51 102C79.1665 102 102 79.1665 102 51C102 22.8335 79.1665 0 51 0C22.8335 0 0 22.8335 0 51ZM8.5 51C8.5 27.5279 27.5279 8.5 51 8.5C74.4721 8.5 93.5 27.5279 93.5 51C93.5 74.4721 74.4721 93.5 51 93.5C27.5279 93.5 8.5 74.4721 8.5 51Z" fill="#0EAD30"/>
            </svg>

            <h2>Thank You!</h2>
            <p>
                Your visit {siteName && 'at '}<span
                style={{'color': '#0EAD30', 'font-weight': 'bold'}}>{siteName && siteName.replace('+', ' ')}</span> has
                been successfully
                recorded.{" "}
                <strong>Please show this success message to your host or check in another guest.</strong>
            </p>
            <p className="guest-count-title">Guests checked-in:</p>
            <span className="guest-count">{counter}</span>
            <button className="btn-add-guest" onClick={handleAddGuest} type="submit">
                <svg width="16" height="16" viewBox="0 0 10 10" fill="none">
                <path d="M5.41671 4.58334H8.75004C8.98016 4.58334 9.16671 4.76988 9.16671 5C9.16671 5.23012 8.98016 5.41667 8.75004 5.41667H5.41671V8.75C5.41671 8.98012 5.23016 9.16667 5.00004 9.16667C4.76992 9.16667 4.58337 8.98012 4.58337 8.75V5.41667H1.25004C1.01992 5.41667 0.833374 5.23012 0.833374 5C0.833374 4.76988 1.01992 4.58334 1.25004 4.58334H4.58337V1.25C4.58337 1.01988 4.76992 0.833336 5.00004 0.833336C5.23016 0.833336 5.41671 1.01988 5.41671 1.25V4.58334Z" fill="#2d2d2d"/>
            </svg>
                 Add another guest
            </button>
        </div>
    )
}
export default SuccessMsg