import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import useLocalStorage from "./hooks/useLocalStorage";
import { getStateShort, STATE_QLD, STATE_VIC } from './helpers/states'; 
import SuccessMsg from "./SuccessMsg";
import Scan from "./Scan";
import Header from "./Header";

const showAddressFieldFor = [STATE_QLD];
const showTableNumFieldFor = [STATE_VIC];
const showEmailFieldFor = [STATE_QLD];

const GEO_SERVICE_URL = "https://get.geojs.io/v1/ip/geo.json";
const FORM_URL = "https://3b2tl9xfql.execute-api.ap-southeast-2.amazonaws.com/Prod/submit";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const siteName = urlParams.get("siteName");
const siteId = urlParams.get("siteId");
const siteState = urlParams.get("state") ? urlParams.get("state").toUpperCase() : '';

function App() {
  const submitResponse = document.querySelector("#response");
  const [isFetching, setIsFetching] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showTableNum, setShowTableNum] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [counter, setCount] = useState(0);
  const env = process.env.NODE_ENV;
  const [storedFormData, setStoredFormData] = useLocalStorage("ls_checkin");

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    defaultValues: {
      rememberMe: true,
    },
  });

  const marketingComms = watch("marketingComms");
  const rememberMe = watch("rememberMe");
  if (siteId === null) {
    return <Scan />;
  }
  const checkLocalStorage = () => {
    if (counter === 0) {
      storedFormData && setValue("fullName", storedFormData.fullName);
      storedFormData && setValue("email", storedFormData.email);
      storedFormData && setValue("streetAddress", storedFormData.streetAddress);
      storedFormData && setValue("mobileNumber", storedFormData.mobileNumber);
      storedFormData && setValue("postCode", storedFormData.postCode);
    }
  };
  useEffect(() => {
    setIsFetching(true);

    if (siteState) {
      setShowAddress(showAddressFieldFor.includes(siteState));
      setShowTableNum(showTableNumFieldFor.includes(siteState));
      setShowEmail(showEmailFieldFor.includes(siteState));
      setIsFetching(false);
    } else {
      const fetchState = () =>
        fetch(GEO_SERVICE_URL)
          .then(function (response) {
            if (!response.ok) {
              throw new Error('GeoIP lookup failed');
            }
            return response.json();
          })
          .then(function (data) {
            let fetchedState = getStateShort(data.region || '');
            if (!fetchedState) {
              throw new Error('Unable to find state');
            }
            setShowAddress(showAddressFieldFor.includes(fetchedState));
            setShowTableNum(showTableNumFieldFor.includes(fetchedState));
            setShowEmail(showEmailFieldFor.includes(fetchedState));
            setIsFetching(false);
          })
          .catch((error) => {
            setShowAddress(true);
            setShowTableNum(true);
            setShowEmail(true);
            setIsFetching(false);
            console.log("e", error);
          });
      fetchState();
    }
  }, []);

  useLayoutEffect(() => {
    checkLocalStorage();
  }, [checkLocalStorage]);

  const onSubmit = (data, event) => {
    event.preventDefault();
    if (rememberMe && counter === 0) {
      setStoredFormData(data);
    }
    if (env === "development") {
      console.log("Sending Dev: ", data);
      setCount(counter + 1);
      setFormSuccess(true);
    } else {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", FORM_URL, true);
      xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      xhr.send(JSON.stringify(data));
      xhr.onloadend = (response) => {
        if (response.target.status === 200) {
          setCount(counter + 1);
          setFormSuccess(true);
        } else {
          submitResponse.innerHTML = "Something went wrong, please try again.";
          console.error(JSON.parse(response.target.response).message);
        }
      };
    }
  };
  const handleAddGuest = () => {
    setFormSuccess(false);
  };
  return (
    <div className='container'>
      <Header theme='dark' />

      {formSuccess ? (
        <SuccessMsg handleAddGuest={handleAddGuest} counter={counter} siteName={siteName} />
      ) : (
        <Fragment>
          <h2>Our goal is to keep all customers safe from COVID-19</h2>
          <p>
            Submit your details to check in {siteName && "at " + siteName.replace("+", " ")} and register this visit. You can check-in multiple guests, one
            after another.
          </p>
          {!isFetching ? (
            <form className='covid-form' onSubmit={handleSubmit(onSubmit)}>
              <div className='input-wrap'>
                <label>Full Name *</label>
                <input
                  type='text'
                  placeholder='Full Name'
                  name='fullName'
                  ref={register({
                    required: true,
                    maxLength: 80,
                    minLength: 2,
                  })}
                  className={errors.fullName ? "error" : ""}
                />
                {errors.fullName && <span className='error-msg'>Please enter a valid name</span>}
              </div>
              <div className='input-wrap'>
                <label>Mobile Number *</label>
                <input
                  type='tel'
                  placeholder='Mobile Number'
                  name='mobileNumber'
                  ref={register({
                    required: true,
                    maxLength: 12,
                    minLength: 8,
                  })}
                  className={errors.mobileNumber ? "error" : ""}
                />
                {errors.mobileNumber && <span className='error-msg'>Please enter a valid phone number</span>}
              </div>
              {showTableNum && (
                <div className='input-wrap'>
                  <label>Table Number / Dining Room *</label>
                  <input
                    type='text'
                    placeholder='Table Number'
                    name='tableNumber'
                    ref={register({
                      required: true,
                      maxLength: 120,
                    })}
                    className={errors.tableNumber ? "error" : ""}
                  />
                  {errors.tableNumber && <span className='error-msg'>Please enter less text</span>}
                </div>
              )}
              {showAddress && (
                <div className='input-wrap'>
                  <label>Street Address</label>
                  <input
                    type='text'
                    placeholder='Street Address'
                    name='streetAddress'
                    ref={register({
                      required: false,
                      maxLength: 120,
                      minLength: 4,
                    })}
                    className={errors.streetAddress ? "error" : ""}
                  />
                  {errors.streetAddress && <span className='error-msg'>Please enter a valid address</span>}
                </div>
              )}
              {!showAddress && (
                <div className='input-wrap'>
                  <label>Postcode</label>
                  <input
                    type='text'
                    placeholder='Postcode'
                    name='postCode'
                    ref={register({
                      required: false,
                      maxLength: 8,
                      minLength: 4,
                    })}
                    className={errors.postCode ? "error" : ""}
                  />
                  {errors.postCode && <span className='error-msg'>Please enter a valid postcode</span>}
                </div>
              )}
              {(marketingComms || showEmail) && (
                <div className='input-wrap'>
                  <label>Email *</label>
                  <input
                    type='text'
                    placeholder='Email'
                    name='email'
                    ref={register({
                      required: true,
                      maxLength: 80,
                      minLength: 6,
                      pattern: /^\S+@\S+$/i,
                    })}
                    className={errors.email ? "error" : ""}
                  />
                  {errors.email && <span className='error-msg'>Please enter a valid email </span>}
                </div>
              )}
              <div className='input-wrap checkbox'>
                <label>
                  <input type='checkbox' name='marketingComms' ref={register} />
                  I’d like to receive marketing communications from this venue.
                  <span className='checkmark' />
                </label>
              </div>
              <input type='hidden' name='siteName' value={siteName} ref={register} />
              <input type='hidden' name='siteId' value={siteId} ref={register} />
              <p id='response' className='error-msg' />
              <input type='submit' />
              {counter === 0 && (
                <div className='input-wrap small checkbox'>
                  <label className='switch'>
                    <input type='checkbox' name='rememberMe' ref={register} />
                    <span className='slider round'> </span>
                  </label>
                  <span className='label-copy'>Remember my details for next time</span>
                </div>
              )}
            </form>
          ) : (
            <div style={{ display: "flex" }}>
              <svg className='loading-kounta animated infinite pulse' width='18' height='27' viewBox='0 0 18 27'>
                s
                <g id='loading-k'>
                  <path d='M8.98346 0L10.2307 2.16526C10.3558 2.38494 10.4216 2.63339 10.4216 2.88619C10.4216 3.13899 10.3558 3.38743 10.2307 3.60711L2.85436 16.464L6.33176 22.5257C6.59698 22.9948 6.98199 23.385 7.44744 23.6566C7.9129 23.9281 8.4421 24.0712 8.98097 24.0712C9.51983 24.0712 10.049 23.9281 10.5145 23.6566C10.9799 23.385 11.365 22.9948 11.6302 22.5257L15.1076 16.464L14.1671 14.8176L10.2307 21.7025C10.1045 21.9217 9.92276 22.1037 9.70379 22.2303C9.48483 22.3569 9.23638 22.4235 8.98346 22.4235C8.73055 22.4235 8.48209 22.3569 8.26313 22.2303C8.04416 22.1037 7.86242 21.9217 7.73619 21.7025L4.74273 16.464L11.5778 4.52261L12.8251 6.68538C12.9506 6.90535 13.0167 7.15426 13.0167 7.40755C13.0167 7.66083 12.9506 7.90974 12.8251 8.12972L8.04302 16.464L8.98346 18.0904L14.1721 9.04521L17.5722 14.9673C17.8285 15.4168 17.9634 15.9253 17.9634 16.4428C17.9634 16.9603 17.8285 17.4688 17.5722 17.9183L14.1147 23.9476C13.5809 24.8706 11.9495 26.9411 8.97598 26.9411C6.00248 26.9411 4.38102 24.8806 3.84968 23.9476L0.394736 17.9308C0.136089 17.4798 0 16.9689 0 16.449C0 15.9291 0.136089 15.4183 0.394736 14.9673L8.98346 0Z'>
                    {" "}
                  </path>
                </g>
              </svg>
            </div>
          )}
        </Fragment>
      )}

      <p className='footnote'>
        <a href='https://www.lightspeedhq.com.au/privacy-policy/' rel='noopener noreferrer' target='_blank'>
          *Your data will be stored securely in line with our <span className='link'>privacy policy</span>, and unless you have specifically opted in for the
          venue’s marketing communications, it’ll be used only for the purposes of tracing COVID-19 infections. Please note that Lightspeed will not use your
          data for any communications.
        </a>
      </p>
    </div>
  );
}

export default App;
