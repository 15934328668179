import React from "react";
import Header from "./Header";

const landingPage = () => {
    return (
        <div className="landing-container container">

        <Header theme="light"/>
            <div className="landing-page">
                <div id="overlay"></div>
                <div className="inner">
                <h2>Do you run a<br/>
                    hospo business?</h2>
                <p>Discover contactless payments, delivery & takeaway, safe check-ins & much more with hospitality’s most connected POS platform.</p>
                 <a href="https://www.lightspeedhq.com.au/pos/restaurant/" className="btn-blue">Find out more</a>
                </div>
            </div>
            </div>
    )
}
export default landingPage