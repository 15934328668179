import React, {Fragment} from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Footer from "./Footer";
import "./styles.css";

const rootElement = document.getElementById("root");
ReactDOM.render(
    <Fragment>
            <App/>
        <Footer/>
    </Fragment>, rootElement);
