export const STATE_QLD = 'QLD';
export const STATE_NSW = 'NSW';
export const STATE_SA = 'SA';
export const STATE_TAS = 'TAS';
export const STATE_VIC = 'VIC';
export const STATE_WA = 'WA';
export const STATE_ACT = 'ACT';
export const STATE_NT = 'NT';
const states = {
    'Queensland': STATE_QLD,
    'New South Wales': STATE_NSW,
    'South Australia': STATE_SA,
    'Tasmania': STATE_TAS,
    'Victoria': STATE_VIC,
    'Western Australia': STATE_WA,
    'Australian Capital Territory': STATE_ACT,
    'Northern Territory': STATE_NT
}
/**
 * @param {string} state 
 * @returns {string}
 */
export function getStateShort(state) {
    const lower = state.toLowerCase();
    for (let [long, short] of Object.entries(states)) {
        if (long.toLowerCase() === lower) {
            return short;
        }
    }
}